<template>
  <div class="carousel">
    <img class="banner-img" :src="imgUrl">
    <div class="content">
      <div
        v-for="item in filteredContentData"
        :key="item.id"
        class="content-box"
      >
        <div class="left-box" style="width:500px">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.description }}</div>
        </div>
        <div class="right-box">
          <img class="banner-img" src="@/assets/icon/smart-ward.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      contentData: [
        {
          id: 1, // 示例唯一标识符
          type: 'ward',
          title: '病房智能交互系统',
          description: '基于物联网传输技术，实现医护人员与住院患者之间直接、可靠的信息联络对医院病床和呼叫设备统一进行管理，并引入智能穿戴设备的应用，护士在动态情况下也能够接收到病房患者的及时呼叫提醒。'
        },
        {
          id: 2, // 示例唯一标识符
          type: 'bedside',
          title: '床旁智能交互系统',
          description: '床旁智能交互系统通过安装在患者床旁的智能交互屏配以机械摇臂的方式，可灵活跳动屏幕位置。系统实现医嘱查询、报告查询、健康宣教、通话呼叫、影音娱乐、订餐服务、医生查房、院内导航、体征录入等功能，提高患者的住院体验，方便医护人员进行床旁无纸化护理工作，提高护理工作效率。'
        },
        {
          id: 3, // 示例唯一标识符
          type: 'nurse',
          title: '护士站智能交互系统',
          description: '护士站智能交互系统通过放在护士站的智能交互大屏，与病房终端、医院His系统进行数据互联互通，实现院内数据统一整合显示。包括病区概况、患者信息、今日治疗、备忘录、交班信息、呼叫响应、健康宣教、护士排班、危急值预警等信息，帮助医护人员快速获取工作重点，优化工作流程，提高工作效率。'
        }
      ]
    };
  },
  computed: {
    imgUrl() {
      const imgMap = {
        'ward': require('@/assets/solution/ward-header-background.png'),
        'bedside': require('@/assets/solution/ward-header-background.png'),
        'nurse': require('@/assets/solution/ward-header-background.png')
      };
      return imgMap[this.from] || '';
    },
    filteredContentData() {
      return this.contentData.filter(item => item.type === this.from);
    }
  }
};
</script>

<style scoped lang="scss">
.carousel {
  width: 100%;
  height: 660px;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    .content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1280px;
      margin: 0 auto;
      position: relative;

      .left-box {
        position: relative;

        .title {
          height: 62px;
          line-height: 62px;
          font-size: 44px;
          font-weight: 400;
          color: #ffffff;
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          line-height: 32px;
          margin-left: 8px;
          margin-top: 32px;
        }
      }
      .right-box {
        width: 629px;
        height: 430px;
        // background: rgba(0,0,0,0.2);
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .carousel {
    height: auto;
  }
}
</style>
