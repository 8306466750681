<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox">
    <!-- header -->
    <wardHeader from="nurse" />
    <!-- 产品亮点 -->
    <sectionTitle
      title-c-n="产品亮点"
      title-e-n="Product Highlights"
    />
    <!-- 产品介绍 -->
    <description from="nurse" />
  </div>
</template>

<script>
import wardHeader from './components/header.vue';
import sectionTitle from './components/sectionTitle.vue';
import description from './components/description.vue';
export default {
  name: 'SmartWard',
  components: {
    wardHeader,
    sectionTitle,
    description
  },
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>

</style>
