<!-- 板块标题 -->
<template>
  <div class="section-title">
    <div class="title-cn">{{ titleCN }}</div>
    <div class="title-en">{{ titleEN }}</div>

    <div v-if="highlightList.length" class="highlight-block">
      <div
        v-for="(item, index) in highlightList"
        :key="index"
        :class="['highlight-item', itemClasses(index)]"
      >
        <div class="highlight-title" v-text="item.title" />
        <img class="highlight-img" :src="item.img" alt="">
        <div class="highlight-text" v-text="item.text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleCN: {
      type: String,
      default: ''
    },
    titleEN: {
      type: String,
      default: ''
    },
    highlightList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    itemClasses(index) {
      const length = this.highlightList.length;
      if (index === 0) {
        return 'next';
      } else if (index === length - 1) {
        return 'prev';
      } else {
        return 'prev next';
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.section-title {
  width: 100%;
  margin-top: 70px;
  .title-cn {
    height: 56px;
    line-height: 56px;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #0b1d30;
  }
  .title-en {
    height: 35px;
    line-height: 35px;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    color: #333333;
    opacity: 0.08;
    transform: translateY(-28px);
  }
  .highlight-block {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .highlight-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 380px;
      height: 420px;
      background: #f1f5fb;
      border-radius: 20px;
      padding: 30px;
      &.prev {
        &:before {
          content: '';
          box-sizing: border-box;
          position: absolute;
          width: 0;
          height: calc(100% - 40px);
          border-left: 20px solid #ffffff;
          border-right: 0 solid transparent;
          border-top: 190px solid transparent;
          border-bottom: 190px solid transparent;
          left: 0;
          top: 20px;
          z-index: 1;
        }
      }
      &.next {
        // 箭头三角形
        &:after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          width: 0;
          height: calc(100% - 40px);
          border-left: 20px solid #f1f5fb;
          border-right: 0 solid transparent;
          border-top: 190px solid transparent;
          border-bottom: 190px solid transparent;
          left: 100%;
          top: 20px;
          z-index: 1;
        }
      }
      .highlight-title {
        width: 100%;
        height: 33px;
        line-height: 33px;
        font-size: 24px;
        font-weight: 600;
        text-align: left;
        color: #333333;
      }
      .highlight-img {
        width: 227px;
        height: 163px;
        margin: 40px 0 30px;
      }
      .highlight-text {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }
  }
}
</style>
