<!-- 产品介绍 -->
<template>
  <div class="product-description">
    <div class="product-description_list">
      <div v-for="(item, index) in listMap[from]" :key="index" class="product-description_item" :class="{'is-right': item.position === 'right'}">
        <div class="product-description_item_left">
          <div class="product-description_item_name" v-html="item.title" />
          <div class="product-description_item_text" v-html="item.text" />
          <img class="jfy-icon" src="@/assets/icon/jfy-icon.svg" alt="">
        </div>
        <div class="product-description_item_right">
          <div class="product-description_item_img">
            <img :src="item.img" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDescription',
  components: {},
  props: {
    from: {
      type: String,
      default: 'ward'
    }
  },
  data() {
    return {
      listMap: {
        'ward': [{
          position: 'left',
          title: '智能交互主机',
          img: require('@/assets/icon/ward_1.svg'),
          text: '实时响应患者发起的换药呼叫、紧急呼叫、卫生间报警、请求增员等多种呼叫信息，可查询呼叫记录、进行托管、广播、喊话，支持多种患者信息展示包括患者的基本信息、医嘱信息、检查信息'
        }, {
          position: 'right',
          title: '智能交互床头屏',
          img: require('@/assets/icon/ward_2.svg'),
          text: '电子床头卡代替原先的纸质床头卡，可实现患者信息的动态信息显示，包括患者的基本信息、费用信息、风险标志、饮食情况等，除了可以进行呼叫和对讲外，支持健康宣教和注意事项的接收和查看、费用的查询、医院介绍的查询满意度填写等功能'
        }, {
          position: 'left',
          title: '智能交互门口屏',
          img: require('@/assets/icon/ward_3.svg'),
          text: '门口屏实时显示该病房及患者的信息，包括病房号、责任医生、责任护士、液进度等，支持人脸识别和NFC刷卡 (扩展版)医护人员可登录门口屏，查看负责患者的信息、医嘱执行情况、护理信息、手术和检查安排等，方便医护人员获取工作信息。'
        }],
        'bedSide': [{
          position: 'left',
          title: '患者模式提升<span style="color: #30A5FF">就医体验</span>',
          img: require('@/assets/icon/bedSide_1.svg'),
          text: '实时响应患者发起的换药呼叫、紧急呼叫、卫生间报警、请求增员等多种呼叫信息，可查询呼叫记录、进行托管、广播、喊话，支持多种患者信息展示包括患者的基本信息、医嘱信息、检查信息'
        }, {
          position: 'right',
          title: '医护模式便捷<span style="color: #30A5FF">护士体验</span>',
          img: require('@/assets/icon/bedSide_2.svg'),
          text: '电子床头卡代替原先的纸质床头卡，可实现患者信息的动态信息显示，包括患者的基本信息、费用信息、风险标志、饮食情况等，除了可以进行呼叫和对讲外，支持健康宣教和注意事项的接收和查看、费用的查询、医院介绍的查询满意度填写等功能'
        }, {
          position: 'left',
          title: '多种无纸化功能提升<span style="color: #30A5FF">护士效率</span>',
          img: require('@/assets/icon/bedSide_3.svg'),
          text: '门口屏实时显示该病房及患者的信息，包括病房号、责任医生、责任护士、液进度等，支持人脸识别和NFC刷卡 (扩展版)医护人员可登录门口屏，查看负责患者的信息、医嘱执行情况、护理信息、手术和检查安排等，方便医护人员获取工作信息。'
        }],
        'nurse': [{
          position: 'left',
          title: '丰富的<span style="color: #30A5FF">显示模板</span>',
          img: require('@/assets/icon/nurse_1.svg'),
          text: '大屏首页支持病区概况、护理信息、高危管理、预约检查、特殊用药、患者导管、护理任务、预约手术等信息的自定义显示，包含多种显示风格。根据不同病区的内容要求和风格，可灵活调整显示内容、布局和风格。'
        }, {
          position: 'right',
          title: '呼叫播放<span style="color: #30A5FF">及时提醒</span>',
          img: require('@/assets/icon/nurse_2.svg'),
          text: '患者的换药呼叫、紧急呼叫、卫生间报警、护理计时结束等多种呼叫提醒信息同步显示到大屏上，并语音循环播报，提醒护士人员及时跟进护理。'
        }, {
          position: 'left',
          title: '第三方<span style="color: #30A5FF">信息对接展示</span>',
          img: require('@/assets/icon/nurse_3.svg'),
          text: '支持与第三方系统对接，实现护理待办事项、危机值、护理不良事件、护理评估情况的系统联动，另外可与病呼系统、体征采集设备等物联网应用连接，方便医护人员在护士站查看实时数据以及智能警报。'
        }, {
          position: 'right',
          title: '患者信息<span style="color: #30A5FF">一目了然</span>',
          img: require('@/assets/icon/nurse_4.svg'),
          text: '实时显示患者的床位信息，通过系统可查看患者的详情，包括入院信息、医嘱信息、检查检验报告、护理记录、生命体征等信息。'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-description {
  width: 100%;
  background: #f9fcff;
  .product-description_list {
    width: 1280px;
    margin: 0 auto;
    .product-description_item {
      display: flex;
      &.is-right {
        .product-description_item_left {
          order: 2; // 把左侧内容移到右侧
        }
        .product-description_item_right {
          order: 1; // 把右侧内容移到左侧
        }
      }
      .product-description_item_left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .product-description_item_name {
          position: relative;
          height: 42px;
          line-height: 42px;
          font-size: 30px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 21px;
          &::before {
            content: '';
            position: absolute;
            top: -18px;
            left: -18px;
            width: 28px;
            height: 28px;
            background: linear-gradient(90deg,#30a5ff, rgba(48,165,255,0.55));
          }
        }
        .product-description_item_text {
          font-size: 20px;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 24px;
          margin-bottom: 19px;
        }
        .jfy-icon {
          width: 97px;
          height: 28px;
        }
      }
      .product-description_item_right {
        width: 831px;
        height: 760px;
        .product-description_item_img {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
